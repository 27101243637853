import classes from './Header.module.scss';
import LinkButton, { LinkButtonVariant } from '../LinkButton/LinkButton';
import { Link } from 'react-router-dom';
import CapellaLogo from '../../assets/SvgFiles/capella_logo_rgb.svg';
import React, { useEffect } from 'react';
import Login from '../Login/Login';

function Header() {
  return (
    <header className={classes.header}>
      <Link to={'/'} className={classes.logo} aria-label={'Capella Home Page'}>
        <img src={CapellaLogo} alt={''} width="200px" />
      </Link>
      <nav aria-label={'Navigation Links'} className={classes.nav}>
        {/* <LinkButton
          to={'/logs'}
          icon={'module-1'}
          variant={LinkButtonVariant.Plain}
        >
          Logs
        </LinkButton> */}
        <LinkButton
          to={'/blueprints'}
          icon={'boxes-stacked'}
          variant={LinkButtonVariant.Plain}
        >
          Blueprints
        </LinkButton>
        <LinkButton
          external={true}
          to={
            'https://maersk-tools.atlassian.net/wiki/spaces/CSE1/pages/182200241093/Capella'
          }
          icon={'file'}
          variant={LinkButtonVariant.Plain}
        >
          Documentation
        </LinkButton>
        <Login />
      </nav>
    </header>
  );
}

export default Header;
